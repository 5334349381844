<template>
  <InvitationsTable
    :customers="customerOptions"
    :loading="teamStore.invitations === null"
    :is-invitation-manager="isInvitationManager"
    class="mb-3"
    data-testid="team-page.invitaions.section"
    @open-customers-form="openCustomerForm"
    @open-groups-form="openGroupForm"
  />
  <TeamCustomerSelectForm
    :customers="customerOptions"
    :invitations="activeInvitations"
    :open="isCustomerFormOpen"
    :loading="customerSubmitLoading"
    :search-loading="searchLoading"
    @close="isCustomerFormOpen = false"
    @search="searchCustomers"
    @submit="submitCustomers"
  />
  <TeamGroupSelectForm
    :groups="groupOptions"
    :invitations="activeInvitations"
    :open="isGroupFormOpen"
    :loading="groupSubmitLoading"
    @close="isGroupFormOpen = false"
    @submit="submitGroups"
  />
</template>
<script lang="ts" setup>
import {emailGetDomain} from '@/sphere/email';
import {Invitation} from '@/stores/apolloPlatform/team/team';

const {axiosApiInstance} = useApiInstance();

// Stores
const customersStore = useCustomersStore();
const teamStore = useTeamStore();
const userStore = useUserStore();

// Store refs
const {customers} = storeToRefs(customersStore);
const {user} = storeToRefs(userStore);

const searchLoading = ref(false);
const customerSubmitLoading = ref(false);
const groupSubmitLoading = ref(false);

const customerOptions: globalThis.ComputedRef<Customer[]> = computed(() => customers.value || []);
const groupOptions: globalThis.ComputedRef<UserGroup[]> = computed(() => user.value?.groups || []);
const isInvitationManager: globalThis.ComputedRef<boolean> = computed(() => {
  return user.value?.is_superuser === true ||
    user.value?.groups.some((g) => g.name === 'invitation_managers' || g.name === 'Superaccess')
    ? true
    : false;
});

const activeInvitations = ref<Invitation[]>([]);

const isCustomerFormOpen = ref(false);
const openCustomerForm = (invitations: Invitation[]) => {
  isCustomerFormOpen.value = true;
  activeInvitations.value = invitations;
};

const isGroupFormOpen = ref(false);
const openGroupForm = (invitations: Invitation[]) => {
  isGroupFormOpen.value = true;
  activeInvitations.value = invitations;
};

const searchCustomers = async (value: string) => {
  searchLoading.value = true;
  try {
    // Fetch customers using search text
    const customers: Customer[] = await customersStore.readCustomers(value);
    // Merge results with options model(i.e customersStore.customers)
    const duplicateCustomers: Customer[] = [...customerOptions.value, ...customers];
    // https://stackoverflow.com/questions/2218999/how-to-remove-all-duplicates-from-an-array-of-objects
    const uniqueCustomers: Customer[] = duplicateCustomers.filter(
      (e: Customer, i: number, a: Customer[]) =>
        i === a.findIndex((t: Customer) => t.id === e.id && t.name === e.name), // element, index, array
    );
    // Set the new de-duplicated customers
    await customersStore.setCustomers(uniqueCustomers);
  } catch (error) {
    console.log(error);
  } finally {
    searchLoading.value = false;
  }
};

const submitCustomers = async (customers: Customer[]) => {
  customerSubmitLoading.value = true;
  try {
    await Promise.all(
      activeInvitations.value.map((i) => {
        return customers.map(async (customer) => {
          const role = i.customers[0]?.role || 'em';
          /**
           * When selecting multiple invites, if a customer is not common to a single invite
           * Then the customer will be displayed as an option to add to multiple invites.
           * If the customer already exists in this invite, then no need to send the request
           */
          if (i.customers.find((c) => c.id === customer.id) === undefined) {
            await axiosApiInstance({
              method: 'put',
              url: `invitations/${i.id}/add_customer/`,
              data: {
                customer_id: customer,
                role: role,
              },
            });
          }
        });
      }),
    );
    /**
     * Requests for adding customers to invites OK, but don't show up when
     * fetchInvitation is called too soon. TODO: re-write team store
     */
    setTimeout(async () => {
      await teamStore.fetchInvitations();
    }, 1000);
  } catch (error) {
    console.log(error);
  } finally {
    customerSubmitLoading.value = false;
    isCustomerFormOpen.value = false;
  }
};

const submitGroups = async (groups: Role[]) => {
  groupSubmitLoading.value = true;
  try {
    await Promise.all(
      activeInvitations.value.map((i) => {
        const inviteDomain = emailGetDomain(i.email);
        return groups.map(async (group) => {
          /**
           * When selecting multiple invites, if a group is not common to a single invite
           * Then the group will be displayed as an option to add to multiple invites.
           * If the group already exists in this invite, then no need to send the request
           */
          if (i.groups.find((g) => g.id === group.id) === undefined) {
            // Does the group to add allow the current invite's domain?
            if (
              group.allowed_domains === null ||
              group.allowed_domains === '' ||
              group.allowed_domains?.includes(inviteDomain) === true
            ) {
              await axiosApiInstance({
                method: 'put',
                url: `invitations/${i.id}/add_group/`,
                data: {
                  group_id: group.id,
                },
              });
            }
          }
        });
      }),
    );
    /**
     * Requests for adding groups to invites OK, but don't show up when
     * fetchInvitation is called too soon. TODO: re-write team store
     */
    setTimeout(async () => {
      await teamStore.fetchInvitations();
    }, 1000);
  } catch (error) {
    console.log(error);
  } finally {
    groupSubmitLoading.value = false;
    isGroupFormOpen.value = false;
  }
};
</script>
