<template>
  <div class="sidebar-links">
    <CoreListGroupItem
      v-for="(child, index) in node.children"
      :key="child.page.id"
      :item="child"
      :disabled="canNavigateToPage(child)"
      :top-level="index === 0"
      @collapse="handleCollapse"
    >
      <div style="padding-left: 0.5rem">
        <CoreListGroup
          v-if="!child.collapsed"
          :node="child"
          :core-slug="coreSlug"
          @collapse="handleCollapse"
        />
      </div>
    </CoreListGroupItem>
  </div>
  <div v-if="coreSlug === 'bnr' || coreSlug === 'offertetool'" v-bind="$attrs">
    <TeleportTarget target-id="sidebar-dynamic-content" />
  </div>
</template>

<script lang="ts" setup>
import {TreeNodeCore, TreeNodePage} from '@/stores/apolloPlatform/cores/portalNavigation';

interface Props {
  node: TreeNodeCore | TreeNodePage;
  coreSlug?: string;
  route?: string;
}

defineProps<Props>();

const emits = defineEmits(['collapse']);

const handleCollapse = (item: any) => {
  emits('collapse', item);
};

const canNavigateToPage = (child: TreeNodePage) => {
  return child.page.class_name !== 'Page' && !child.page.route && child.page.embeddings.length > 0;
};
</script>

<style lang="scss" scoped>
.sidebar-links > li {
  display: block;
  width: 100%;
  position: relative;
}

[data-bs-theme='dark'] .sidebar-links > li a {
  color: var(--bs-emphasis-color);
}

[data-bs-theme='light'] .sidebar-links > li a {
  color: var(--bs-body-color);
}

.sidebar-links li a {
  font-weight: 500;
}

.sidebar-links > li a {
  padding: 12px 15px;
  position: relative;
  font-weight: 500;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.sidebar-links li a span {
  letter-spacing: 0.7px;
  font-family: Roboto;
  text-transform: capitalize;
  color: var(--bs-body-color);
}

[data-bs-theme='dark'] .sidebar-links > li a svg {
  color: var(--bs-emphasis-color);
}

[data-bs-theme='light'] .sidebar-links > li a svg {
  color: var(--bs-body-color);
}

.sidebar-links > li a svg {
  width: 18px;
  margin-right: 10px;
  vertical-align: bottom;
  float: none;
  stroke-width: 2px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.sidebar-links > li .mega-menu-container .mega-box .link-section .submenu-title .h5::after,
.sidebar-links > li .sidebar-submenu li a::after {
  // position: absolute;
  content: '';
  top: 48%;
  width: 6px;
  color: var(--bs-body-color);
}

.sidebar-links > li .sidebar-submenu li a {
  padding: 7px 25px;
  font-size: 14px;
  display: block;
  position: relative;
  letter-spacing: 0.06em;
  font-weight: 500;
  font-family: Roboto;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>
