<template>
  <div class="relative mb-3">
    <label class="form-label d-block">
      {{ translateText('Rate your experience') }}
      <span class="text-danger">*</span>
    </label>
    <div v-for="option in options" :key="option" class="form-check form-check-inline p-0 me-1">
      <input
        :id="option"
        v-model="model"
        type="radio"
        :value="option"
        class="btn-check form-check-input"
        name="rating"
        autocomplete="off"
        required
      />
      <label
        class="form-check-label face btn btn-ghost d-flex d-inline px-0 py-1 me-2"
        :for="option"
      >
        <Icon
          :icon="icons.get(option)"
          class="fs-1"
          :class="[getActiveIconClass(option), `icon icon-${option}`]"
          :data-testid="'feedback.form.rating.' + option"
        />
      </label>
    </div>
    <span role="alert" aria-atomic="true" class="text-danger invalid-feedback w-100">
      {{ translateText('Rate your experience is required') }}
    </span>
  </div>
</template>
<script lang="ts" setup>
import translate from '@/translate';

const props = defineProps<{
  modelValue: string;
}>();

const emits = defineEmits<{
  'update:modelValue': [value: string];
}>();

const model = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emits('update:modelValue', value);
  },
});

const userSettingsStore = useUserSettingsStore();
const language = computed(() => userSettingsStore.userSettings.language);
const translateText = (input: string) => translate.translate(language.value, input);
const options = ref(['happy', 'neutral', 'sad']);
const icons: globalThis.Ref<Map<string, string>> = ref(new Map());
const getActiveIconClass = (option: string) => {
  return model.value === option ? `icon-${option}-active` : '';
};

onMounted(() => {
  icons.value.set('happy', 'mdi:smiley-outline');
  icons.value.set('neutral', 'mdi:smiley-neutral-outline');
  icons.value.set('sad', 'mdi:emoticon-sad-outline');
});
</script>
<style lang="scss" scoped>
.face {
  .icon {
    transition: all 0.2s ease-in-out;
  }
}

.face:hover {
  .icon {
    scale: 1.2;
  }
}

.face:active {
  .icon {
    transition: all 0.3s ease;
    scale: 1;
  }
}

$icons: (
  'happy': 'success',
  'neutral': 'warning',
  'sad': 'danger',
);

@each $name, $color in $icons {
  .icon-#{$name}:hover :deep(svg) {
    fill: var(--bs-#{$color}-border-subtle);
    color: var(--bs-#{$color});
  }

  .icon-#{$name}-active :deep(svg) {
    fill: var(--bs-#{$color}-border-subtle);
    color: var(--bs-#{$color});
  }
}
</style>
