import {AuthenticationResult} from '@azure/msal-browser';
import {UserSettings} from '../userSettings/userSettings';

export type UserRole = {
  id: number;
  customer_id: number;
  customer: string;
  debtor_number: string;
  user_id: number;
  username: string;
  role: string;
  created: string;
  active: boolean;
};
export type UserRoleUpdate = {
  user_id: number;
  customer_id: number;
  relation_id: number;
  role: string;
};

export type Role = {
  name: string;
  value: string;
  relation_id?: number;
};

export type UserReportRole = {
  title: string;
  description: string;
  class_name: string;
};

export type UserGroup = {
  id: number;
  name: string;
  default_group: string;
  allowed_domains: string[];
  group_type: string;
};

export type User = {
  id: number;
  username: string;
  first_name: string;
  last_name: string;
  email: string;
  is_staff: boolean;
  is_superuser: boolean;
  class_name: string;
  report_roles: UserReportRole[];
  groups: UserGroup[];
  user_settings: UserSettings;
  USER_FEEDBACK_API_KEY: string;
  PRODUCT_CATALOGUE_API: string;
  BACKEND_ADMIN: string;
};

export type UserPermissions = {
  user_permissions: string[];
  user_management_enabled: boolean;
};

export const useUserStore = defineStore('user', {
  state: () => ({
    user: null as User | null,
    msalToken: null as AuthenticationResult | null,
    userPermissions: null as UserPermissions | null,
  }),
  getters: {
    getFeedbackPermission: (state) =>
      state.userPermissions?.user_management_enabled === false ||
      state.userPermissions?.user_permissions.some((p: string) => p === 'feedback'),
  },
  actions: {
    setUser(user: User) {
      this.user = user;
    },
    setUserPermissions(userPermissions: UserPermissions) {
      this.userPermissions = userPermissions;
    },
    setMsalToken(msalToken: AuthenticationResult | undefined) {
      this.msalToken = msalToken ?? null;
    },
    async readUser(): Promise<User | undefined> {
      const {data} = await apiGetUser();
      this.setUser(data);

      const userSettingsStore = useUserSettingsStore();
      userSettingsStore.replaceUserSettings(data.user_settings);
      return data as User;
    },
    async readUserPermissions() {
      try {
        const {data} = await apiGetUserPermissions();
        this.setUserPermissions(data);
        return data as UserPermissions;
      } catch (error) {
        throw apiErrorMessage(error);
      }
    },
    async updateUserRole(userRole: UserRoleUpdate): Promise<UserRole | undefined> {
      try {
        const {data} = await apiPutUserRole(userRole);
        return data as UserRole;
      } catch (error) {
        throw apiErrorMessage(error);
      }
    },
    async deleteUserCustomerRelation(id: number, customerId: number) {
      try {
        await apiDeleteUserCustomerRelation(id, customerId);
      } catch (error) {
        throw apiErrorMessage(error);
      }
    },
  },
});
