export const useCustomersStore = defineStore('customers', {
  state: () => ({
    selectedCustomer: null as SelectedCustomer | null,
    customers: null as Customer[] | null,
  }),
  actions: {
    setSelectedCustomer(selectedCustomer: SelectedCustomer) {
      const {setItem} = useLocalStorage();
      setItem('selectedCustomer', selectedCustomer);
      this.selectedCustomer = selectedCustomer;
    },
    setCustomers(customers: Customer[]) {
      this.customers = customers;
    },
    async readCustomers(search = ''): Promise<Customer[]> {
      const {getItem} = useLocalStorage();
      const localStorageCustomers: Customer[] | null = getItem('customers');
      if (localStorageCustomers) {
        this.setCustomers(localStorageCustomers);
      }
      try {
        // Get the first cursor data only.
        const response = await apiGetCustomers(undefined, search);
        const data: GenericCursorFn<Customer> = cursor(response.data);
        /**
         * If a search argement was provided, get all search cursor results.
         * Don't setCustomers as search results will remove previously cached customers.
         */
        if (search.length > 0) {
          // Get the next cursor data
          const aggregatedCustomerResults: Customer[] =
            data.next !== null ? await apiGetPaginatedResults(apiGetCustomers, data.next) : [];
          // Return both the first cursor & rest of the aggregated cursors.
          return [...data.results, ...aggregatedCustomerResults];
        } else {
          this.setCustomers(data.results);
          return data.results; // Return the first cursor only.
        }
      } catch (error) {
        throw apiErrorMessage(error);
      }
    },
    async readCustomerById(id: number): Promise<SelectedCustomer> {
      const {getItem} = useLocalStorage();
      const localStorageSelectedCustomer: SelectedCustomer | null = getItem('selectedCustomer');
      if (localStorageSelectedCustomer) {
        this.setSelectedCustomer(localStorageSelectedCustomer);
      }
      try {
        const {data} = await apiGetCustomer(id);
        this.setSelectedCustomer(data);
        return data;
      } catch (error) {
        throw apiErrorMessage(error);
      }
    },
  },
});
