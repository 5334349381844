<template>
  <div>
    <div
      class="container"
      style="height: calc(100vh - 65px); width: 40%; margin: auto; float: left; padding: 10% 10%"
      data-testid="error-page.message.container"
    >
      <div
        id="error-page-box"
        class="card"
        style="padding: 10%; width: auto; height: auto"
        data-testid="error-page.message.card"
      >
        <title data-testid="error-page.message.title">{{ translate(title) }}</title>
        <h3 data-testid="error-page.message.title.h3" style="padding-bottom: 10px">
          {{ translate(heading) }}
        </h3>
        <h4
          data-testid="error-page.message.user-email"
          style="padding-bottom: 50px; font-size: 20px; white-space: wrap"
        >
          {{ user ? user.email : 'Unsuccessful authentication' }}
        </h4>
        <br />
        <div v-for="text in texts" :key="text" data-testid="error-page.message.text">
          <p>{{ translate(text) }}</p>
        </div>
        <div style="padding-top: 50px">
          <a style="cursor: pointer" @click="logout">
            <Icon
              icon="material-symbols:login"
              class="float-left"
              data-testid="error-page.message.button.log-in"
            />
            <span style="float: left" data-testid="error-page.message.button.log-out">Log out</span>
          </a>
        </div>
      </div>
    </div>
    <div
      class="container"
      style="
        background-size: cover;
        background-position: center center;
        display: block;
        background-image: url(https://sphere.ek.co/img/login_right.jpg);
        float: right;
        height: calc(100vh - 65px);
        width: 60%;
      "
      data-testid="error-page.image.conatiner"
    ></div>
  </div>
</template>

<script>
import {mapState} from 'pinia';
import translate from '@/translate';
import config from '@/config';

export default {
  name: 'ErrorPage',
  props: {},
  data() {
    return {
      type: null,
      title: '',
      heading: '',
      texts: [],
    };
  },

  computed: {
    ...mapState(useUserSettingsStore, ['userSettings']),
    ...mapState(useUserStore, ['user']),
    ...mapState(useErrorStore, ['error']),
    language() {
      return this.userSettings.language;
    },
  },

  created: function () {
    this.removeItem('selectedCustomers');

    const errorType = this.error?.type;

    if (errorType === 'customerInvitation') {
      this.title = 'Oops... There seems to be a problem...';
      this.heading = 'Oops... There seems to be a problem...';
      this.texts = [
        'There are no customers or invitations configured for this user.',
        'Want to know more? contact sdnl@ek.co to request access.',
      ];
    } else if (errorType === 'unknownUser') {
      this.title = 'Oops... There seems to be a problem...';
      this.heading = 'Oops... There seems to be a problem...';
      this.texts = [
        'You are not yet known in the Ekco Sphere',
        'This is probably because you have not been invited by your organisation.',
        'Want to know more? contact sdnl@ek.co to request access.',
      ];
    } else if (errorType === 'duplicate_profile') {
      this.title = 'Login issue...';
      this.heading = 'Login issue...';
      this.texts = [
        "A different account exists with matching profile info that's supposed to be unique.",
        'Make sure you use the same login provider as the previous time you logged in.',
        'Contact sdnl@ek.co if you think this is an issue on our side, or if you migrated to a different login provider.',
      ];
    } else {
      // Unknown error
      this.title = 'Oops... There seems to be a problem...';
      this.heading = 'Oops... There seems to be a problem...';
      this.texts = [
        'There was an error while loading the page.',
        'Please try again later.',
        'Want to know more? contact sdnl@ek.co to request access.',
      ];
    }
  },

  methods: {
    ...mapActions(useLocalStorage, ['removeItem']),

    translate(input) {
      return translate.translate(this.language, input);
    },

    async logout() {
      await this.$msal.instance.logoutRedirect({
        account: this.$msal.instance.getAllAccounts()[0],
        postLogoutRedirectUri: config.REDIRECT_URL,
      });
    },
  },
};
</script>
