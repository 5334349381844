<template>
  <HeaderLayout data-testid="header" @update:sidebar-toggle="$emit('update:sidebar-toggle')">
    <template #sidebar-toggle>
      <div class="d-flex align-items-center">
        <router-link to="/" data-testid="header.logo" class="me-2">
          <LogoEkco />
        </router-link>
        <IconButton
          v-if="sidebarCollapsed"
          id="sidebar-toggle"
          icon-name="material-symbols:menu"
          data-testid="header.sidebar.toggle.expand"
          variant="ghost"
          size="lg"
          class="mx-2"
          :loading="loading"
          @click="$emit('update:sidebar-toggle')"
        />
      </div>
    </template>
    <template #left>
      <div v-if="!(isMobile || isTablet)" class="d-flex align-items-center w-100">
        <div v-if="showCurrentPageTitle" class="d-flex align-items-center">
          <BootstrapNav
            class="d-none d-lg-flex"
            :class="getCurrentPageTitleClass()"
            data-testid="header.breadcrumb.current-page"
            :items="[{title: breadCrumbLocation, href: route?.path}]"
            :active-route="route"
          />
        </div>
        <HeaderActions @update:sidebar-toggle="(value) => $emit('update:sidebar-toggle', value)" />
        <SearchComponent class="flex-grow-1"></SearchComponent>
      </div>
    </template>
    <template #right>
      <ul v-if="currentRoute !== 'error'" class="items-right">
        <ImageSwitch
          v-if="currentRoute === 'home'"
          id="topbar-edit-widget"
          data-testid="header.edit-grid.switch"
          class="me-3"
          @change="widgetStore.toggleEditWidgets"
        >
          <img
            src="@/assets/images/edit-3.svg"
            alt="Edit Widgets"
            style="width: 18px; height: 18px"
          />
        </ImageSwitch>
        <CustomerMultiSelectSearch
          id="profile-menu-customer-select"
          data-testid="header.customer-select"
        />
      </ul>
    </template>
    <template #mobile>
      <div v-if="isMobile || isTablet" class="d-flex align-items-center">
        <HeaderActions @update:sidebar-toggle="(value) => $emit('update:sidebar-toggle', value)" />
      </div>
    </template>
  </HeaderLayout>
</template>
<script setup>
import IconButton from '@/components/shared/atoms/IconButton/IconButton.vue';

defineEmits(['update:feedback-open', 'update:sidebar-toggle']);
const props = defineProps({
  feedbackOpen: {
    type: Boolean,
    required: true,
  },
  route: {
    type: Object,
    required: true,
  },
  sidebarCollapsed: {
    type: Boolean,
    required: true,
  },
});

const widgetStore = useWidgetStore();
const coresStore = useCoresStore();
const {isMobile, isTablet} = useBsBreakpoints();

const activeCore = computed(() => props.route.meta.coreNode?.core);

const currentRoute = computed(() => props.route?.name);

const breadCrumbLocation = computed(() => {
  const core = activeCore.value !== undefined ? activeCore.value?.title : currentRoute.value;

  let location = core || '';

  if (location === '') {
    const routeMapping = {
      home: 'home',
      team: 'team',
      news: 'news',
      cookiepolicy: 'cookie policy',
    };

    location = routeMapping[currentRoute.value] || '';
  }

  return location.replaceAll('_', ' ');
});

const getCurrentPageTitleClass = () => {
  return [
    {'px-1': props.sidebarCollapsed}, // Presence of 'px-1' is determined by truthiness of sidebarCollapsed
    {'current-page-title-padding': !props.sidebarCollapsed},
  ];
};

const showCurrentPageTitle = computed(() => {
  return coresStore.navTree !== undefined;
});
</script>
<style lang="scss" scoped>
.items-right {
  display: flex;
  height: 100%;
  align-items: center;
}

.items-right li {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.5rem;
  padding: 0 0.5rem;
  height: 100%;
  cursor: pointer;
}

.items-right li:hover {
  background-color: var(--bs-tertiary-bg);
}

.divider {
  background-color: var(--bs-tertiary-bg);
}

.feedback-open {
  background-color: var(--bs-tertiary-bg);
}

.current-page-title-padding {
  padding-left: 15rem;
}
</style>
