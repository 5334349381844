import {apolloPlatformAxiosInstance as axiosInstance} from './apolloPlatformAxiosInstance';

/**
 * Customers Endpoints
 */

export const apiGetCustomers = async (cursor?: string, search?: string) => {
  return await axiosInstance.get('customers', {params: {cursor, search}});
};

export const apiGetCustomer = async (id: number) => {
  return await axiosInstance.get(`customers/${id}`);
};
