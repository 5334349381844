<template>
  <div style="max-width: 600px; margin: auto">
    <h3 data-testid="error-page.message.title.h3" style="padding-bottom: 10px">
      {{ translateText('Page not found') }}
    </h3>
    <p>
      {{ translateText('Page not found') }}.
      {{ translateText('Use the feedback function if you think this is a mistake') }}.
    </p>
    <p>
      <router-link to="/">{{ translateText('Return to the homepage') }}.</router-link>
    </p>
  </div>
</template>

<script setup lang="ts">
import translate from '@/translate';

const userSettingsStore = useUserSettingsStore();
const language = computed(() => userSettingsStore.userSettings.language);
const translateText = (input: string) => translate.translate(language.value, input);
</script>
