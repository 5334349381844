<template>
  <router-link
    v-if="sidebarCollapsed"
    v-slot="{navigate}"
    :to="to !== 'home' ? `/core/${to}` : `/${to}`"
    custom
  >
    <MenuIconButton
      :id="core.slug"
      :to="core.slug"
      :title="core.title"
      :icon="core.icon"
      :is-home="core.slug === homeCoreSlug"
      class="mb-2"
      @click="
        () => {
          navigate();
          $emit('navigate', to);
        }
      "
    />
  </router-link>
  <div :class="{'d-none': sidebarCollapsed}">
    <div
      ref="menuItem"
      class="menu-item d-flex align-items-center justify-content-between w-100 rounded mb-2 pe-2"
    >
      <router-link v-slot="{navigate}" :to="to !== 'home' ? `/core/${to}` : `/${to}`" custom>
        <div
          class="d-flex flex-grow-1 w-100 align-items-center"
          @click="
            () => {
              navigate();
              $emit('navigate', to);
            }
          "
        >
          <MenuIconButton
            :id="core.slug"
            :to="core.slug"
            :icon="core.icon"
            :is-home="core.slug === homeCoreSlug"
            class="me-3"
          />
          <p class="flex-grow-1 h-100 mb-0">
            {{ core.title }}
          </p>
        </div>
        <IconButton
          v-if="isDropdown"
          :id="`dropdown-chevron-${core.slug}`"
          :icon-name="
            !coreNode.collapsed
              ? 'material-symbols:keyboard-arrow-down'
              : 'material-symbols:chevron-right'
          "
          @click="
            () => {
              toggleCollapsed(coreNode);
              if (isIframeCore) {
                // this triggers the fetching of the menu items
                navigate();
                $emit('navigate', to);
              }
            }
          "
        />
      </router-link>
    </div>
    <slot v-if="isDropdown" :toggle-collapsed="toggleCollapsed"></slot>
  </div>
</template>
<script lang="ts" setup>
import {TreeNodeCore} from '@/stores/apolloPlatform/cores/portalNavigation';
import {scrollIntoView} from '@/sphere/scroll';

const props = defineProps<{
  coreNode: TreeNodeCore;
  activeCoreNode?: TreeNodeCore;
  homeCoreSlug: string;
  sidebarCollapsed: boolean;
}>();

defineEmits<{navigate: [value: string]}>();

const route = useRoute();
const menuItem = ref<HTMLDivElement>();

/**
 * `PortalNavigation` type is used for `props.cores`
 * `props.cores` also contains pages in `children`, these also use the `PortalNavigation` type
 * Top level cores must have a `slug`, this is enforced by BE
 */
const to = computed(() => props.coreNode.core.slug);
const core = computed(() => props.coreNode.core);
const isIframeCore = computed(() => props.coreNode?.core?.iframe !== null);

const isDropdown = computed(() => props.coreNode.core.slug !== 'home');

const toggleCollapsed = (item: TreeNodeCore) => (item.collapsed = !item.collapsed);

watch(
  () => [route.meta.coreNode, route.fullPath, props.sidebarCollapsed],
  (activePage) => {
    if (activePage && route.meta.coreNode?.core.id === props.coreNode.core.id) {
      // await menu element rendered & trigger scrollIntoView
      nextTick(() => {
        scrollIntoView(menuItem.value as HTMLElement, 'start');
      });
    }
  },
  {immediate: true, deep: true},
);
</script>
<style lang="scss" scoped>
.menu-item {
  cursor: pointer;
}
.menu-item:hover {
  background-color: var(--bs-secondary-bg);
}
</style>
