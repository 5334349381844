import {AxiosInstance, AxiosRequestConfig} from 'axios';

/**
 * Returns a new Axios instance
 * @param {string} baseURL
 * @param {ParamsSerializerOptions | CustomParamsSerializer} paramsSerializer
 * @returns {AxiosInstance} An Axios instance.
 */
export const createAxiosInstance = ({
  baseURL,
  paramsSerializer,
}: AxiosRequestConfig): AxiosInstance =>
  axios.create({
    baseURL: baseURL,
    headers: {
      Accept: 'application/json; version=1.0',
      'Content-Type': 'application/json',
    },
    paramsSerializer,
  });
