<template>
  <section class="container-fluid overflow-x-scroll h-100" data-testid="team-page">
    <div class="row w-100">
      <div class="col-md-12 pt-3">
        <TeamMembers
          v-if="!loading"
          class="mb-3"
          :roles="teamStore.roles"
          data-testid="team-page.team-member.section"
        />
        <TeamInvitations />
        <InvitationsForm
          v-if="invitationManager"
          class="mb-5"
          data-testid="team-page.create-new-user.section"
        />
      </div>
    </div>
  </section>
</template>
<script setup lang="ts">
import {useUserStore} from '@/stores/apolloPlatform/user/user';
import {useTeamStore} from '@/stores/apolloPlatform/team/team';

import {computed, onMounted, ref} from 'vue';

const loading = ref(true);

const userStore = useUserStore();
const teamStore = useTeamStore();
const invitationManager = computed(
  () =>
    userStore.user?.is_staff ||
    userStore.user?.groups.some((g) => ['invitation_managers', 'Superaccess'].includes(g.name)),
);

onMounted(async () => {
  await teamStore.getRoles();
  loading.value = false;
});
</script>
