import {AxiosResponse} from 'axios';

/**
 * Interface for generic function that represent paginated cursor data response.
 * @interface GenericCursorFn<Type>
 */
export interface GenericCursorFn<Type> {
  next: string | null;
  previous: string | null;
  results: Type[]; // Add store types as needed
}

// Generic function for GenericCursorFn
export const cursor = <Type>(arg: Type): Type => arg;

/**
 * Callback type for An API module as a global symbol.
 * @callback apiModuleCallback
 * @param {string | undefined} cursor
 * @returns {Promise<AxiosResponse>}
 */

/**
 * Generic Function provided with a StoreType
 * @param {apiModuleCallback} apiModule An API module from the api folder that supports the cursor param.
 * @returns {Promise<StoreType[]>} An aggregated data response of StoreType type parameter.
 */
const aggregatePaginationResults = async <StoreType>(
  apiModule: (cursor?: string) => Promise<AxiosResponse>,
  startingNext: string,
): Promise<StoreType[]> => {
  try {
    // Add store types as needed
    const aggregatedPaginationResults: StoreType[] = [];
    // Set the initial cursor url
    let next: string | null = startingNext?.length > 0 ? startingNext : null;

    do {
      // Get the next cursor url param
      const cursorParam: string | undefined = next ? next.split('cursor=')[1] : undefined;

      // Get the next cursor data
      const response: AxiosResponse = await apiModule(cursorParam);
      const data: GenericCursorFn<StoreType> = cursor(response.data);

      // Aggregate the data
      aggregatedPaginationResults.push(...data.results);

      if (data.next) {
        next = data.next;
      } else {
        next = null;
      }
    } while (next);

    // Return the aggregated data
    return aggregatedPaginationResults;
  } catch (error) {
    throw apiErrorMessage(error);
  }
};

/**
 * Generic Function provided with a StoreType
 * @example <caption>Example using the Customer type.</caption>
 * // Returns Customer[] using the apiGetCustomers module
 * const data: Customer[] = await apiGetPaginatedResults(apiGetCustomers);
 * @param {apiModuleCallback} apiModule An API module from the api folder that supports the cursor param.
 * @returns {Promise<StoreType[]>} An aggregated data response of StoreType type parameter.
 */
export const apiGetPaginatedResults = async <StoreType>(
  apiModule: (cursor?: string) => Promise<AxiosResponse>,
  startingNext: string,
): Promise<StoreType[]> => {
  return await combineRequest(`cached:${apiModule.name}`, () =>
    aggregatePaginationResults<StoreType>(apiModule, startingNext),
  );
};
