<template>
  <ul :class="['nav nav-underline', {'nav-underline-border-bottom': items.length > 1}]">
    <li v-for="item in items" :key="item.title" class="nav-item">
      <router-link v-if="activeRoute.path" v-slot="{navigate}" :to="item.href || ''" custom>
        <a
          :class="[
            'nav-link',
            {
              active: path === item.href && item.href,
              disabled: item.disabled === true || !item.href,
            },
          ]"
          @click="navigate"
        >
          {{ capitalize(item.title) }}
        </a>
      </router-link>
      <a
        v-else
        :class="['nav-link', {active: activeRoute.title === item.title}]"
        @click="$emit('update:activeRoute', item)"
      >
        {{ capitalize(item.title) }}
      </a>
    </li>
  </ul>
</template>
<script lang="ts" setup>
const props = defineProps<{
  items: {title: string; href?: string; disabled?: boolean}[];
  activeRoute: {path?: string; title?: string};
}>();

defineEmits(['update:activeRoute']);

const path = computed(() => props.activeRoute.path || '');
const capitalize = (str: string) => {
  return str
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};
</script>
<style lang="scss" scoped>
.nav-link {
  --bs-nav-link-color: var(--bs-body-color);
  --bs-nav-link-hover-color: var(--bs-body-color);
}

.nav-underline .nav-link {
  border-bottom: 0;
  background-image: none;
  background-repeat: no-repeat;
  background-size: 100% var(--bs-nav-underline-border-width);
  background-position: 0 100%;
  cursor: pointer;
}

.nav-underline-border-bottom {
  // Copying from .card-header as card variables are not available in .nav-underline
  border-bottom: var(--bs-border-width) solid var(--bs-border-color-translucent);
}

.nav-underline .nav-link:hover {
  background-image: linear-gradient(currentcolor, currentcolor);
}
.nav-underline .nav-link.active {
  background-image: var(--ekco-gradient);
}
</style>
