import {apolloPlatformAxiosInstance as axiosInstance} from './apolloPlatformAxiosInstance';
import {NavigationViewSet} from '@/stores/apolloPlatform/cores/portalNavigation';

/**
 * Cores Endpoints
 */

export const apiGetPortalNavigation = async (): Promise<AxiosResponse<NavigationViewSet>> => {
  return await axiosInstance.get('portal_navigation');
};
